/*!

=========================================================
* Black Dashboard React v1.0.0
=========================================================

* Product Page: https://www.vncdigitalservices.com/product/black-dashboard-react
* Copyright 2019 V-PULSE (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/black-dashboard-react/blob/master/LICENSE.md)

* Coded by V-PULSE

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";

// reactstrap components
import {
  Collapse,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Dropdown,
  UncontrolledDropdown,
  ButtonDropdown ,
  Input,
  InputGroup,
  NavbarBrand,
  Navbar,
  NavLink,
  Nav,
  Container,
} from "reactstrap";

import { Link } from "react-router-dom";
class AdminNavbar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      collapseOpen: false,
      modalSearch: false,
      color: "navbar-transparent",
      dropdownOpen: false,
      f_settings: true,
      f_help: true,
      isModalOpen: false
    };

    
    this.showModal = this.showModal.bind(this);
    this.toggle = this.toggle.bind(this);
  }
  componentDidMount() {
    window.addEventListener("resize", this.updateColor);
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.updateColor);
  }

  toggle() {
    this.setState(
      {
        isModalOpen: !this.state.isModalOpen
      }
    );
  }

  showModal() {
    this.setState({
      isModalOpen: true
    });
  }

  // function that adds color white/transparent to the navbar on resize (this is for the collapse)
  updateColor = () => {
    if (window.innerWidth < 993 && this.state.collapseOpen) {
      this.setState({
        color: "bg-white"
      });
    } else {
      this.setState({
        color: "navbar-transparent"
      });
    }
  };
  // this function opens and closes the collapse on small devices
  toggleCollapse = () => {
    if (this.state.collapseOpen) {
      this.setState({
        color: "navbar-transparent"
      });
    } else {
      this.setState({
        color: "bg-white"
      });
    }
    this.setState({
      collapseOpen: !this.state.collapseOpen
    });
  };
  // this function is to open the Search modal
  toggleModalSearch = () => {
    this.setState({
      modalSearch: !this.state.modalSearch
    });
  };
  render() {
    return (
      <>
        <Navbar
          className={classNames("navbar-absolute", this.state.color)}
          expand="lg"
        >
          <Container fluid>
            <div className="navbar-wrapper">
              <div
                className={classNames("navbar-toggle d-inline", {
                  toggled: this.props.sidebarOpened
                })}
              >
                <button
                  className="navbar-toggler toggle-logout-nav"
                  type="button"
                  onClick={this.props.toggleSidebar}
                >
                  <span className="navbar-toggler-bar bar1" />
                  <span className="navbar-toggler-bar bar2" />
                  <span className="navbar-toggler-bar bar3" />
                </button>
              </div>
              <NavbarBrand href="#pablo" onClick={e => e.preventDefault()}>
                {this.props.brandText}
              </NavbarBrand>
            </div>
            <button
              aria-expanded={false}
              aria-label="Toggle navigation"
              className="navbar-toggler toggle-logout-nav"
              data-target="#navigation"
              data-toggle="collapse"
              id="navigation"
              type="button"
              onClick={this.toggleCollapse}
            >
              <span className="navbar-toggler-bar navbar-kebab" />
              <span className="navbar-toggler-bar navbar-kebab" />
              <span className="navbar-toggler-bar navbar-kebab" />
            </button>
            <Collapse navbar isOpen={this.state.collapseOpen}>
              <Nav className="ml-auto" navbar>

                <Dropdown nav inNavbar isOpen={this.state.isModalOpen} toggle={this.toggle}>
                  <DropdownToggle
                    caret
                    color="default"
                    nav
                     >
                    <img className="photo " 
                     src={require("assets/img/anime3.png")} style={{backgroundColor: "white",  height: "40px",
    width: "42px",
    borderradius: "40%" }}/>
                    <b className="caret d-none d-lg-block d-xl-block" />
                    <p className="d-lg-none"></p>
                  </DropdownToggle>
                  <DropdownMenu className="dropdown-navbar" right tag="ul">
                    <NavLink tag="li">
                      <a  href="/vendor" onClick={e=>localStorage.removeItem("token")}><DropdownItem  className="nav-item"  style={{color:"red"}}> LOG OUT</DropdownItem></a>
                      </NavLink>
                  </DropdownMenu>
                </Dropdown>
              </Nav>
            </Collapse>
          </Container>
        </Navbar>

      </>
    );
  }
}

export default AdminNavbar;
