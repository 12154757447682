import React, { Component } from "react";
import axios from "axios";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { apiUrlEvent } from "../constants";
import Toast from "react-bootstrap/Toast";
import SearchField from "react-search-field";
import { Multiselect } from "multiselect-react-dropdown";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import SockJS from "sockjs-client";
import Stomp from "stompjs";
import notification from "../assets/alert.mp3";
import Loader from "react-js-loader";
import Select from "react-select";

import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Table,
  FormGroup,
  Form,
  Row,
  Col,
} from "reactstrap";

class Uploadfile extends Component {
  constructor(props) {
    super(props);

    // Don't call this.setState() here!
    this.state = { disabled: false };
    this.state = {
      showValToast: false,
      beaconIdStr: "",
      meldungsnummer: "",
      beaconIdList: [],
      beaconInfoList: [],
      selectedValue: [],
      selectedValues: [],
      serviceTypeList: [],
      currentDateTime: Date().toLocaleString(),
      vonDate: "",
      showCurrToast: false,
      searchTerm: "",
      showToast: false,
      showSaveToast: false,
      showErrToast: false,
      showDeleteToast: false,
      eventNumber: "",
      category: "",
      beaconUniqueId: "",
      eventType: "",
      eventDescription: "",
      eventPriority: "",
      pointOfContact: "",
      pointOfContactTelephone: "",
      displayForRoles: "",
      periodFrom: new Date(),
      periodTo: new Date(),
      eventStatus: "",
      displayFrom: "",
      titel: "",
      legalComplianceRelevant: "",
      restriction: "",
      // buildNumber: this.state.GebäudeNr,
      floor: "",
      area: "",
      Etage: "",
      propertyNumber: "",
      date: new Date(),
      Rechtssicherheitsrelevant: "Ja",
      notificationArr: [],

      rows: [],
      filteredData: [],
      originalData: [],
      prioritySearchData: [],
      titleSearchData: [],
      hallInfoList: [],
      searchInput: "",
      selectedFile: null,
      showModalPopup: false,
      showLoader:false
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleToDateChange = this.handleToDateChange.bind(this);
    this.dateValidation = this.dateValidation.bind(this);
    this.handleShowToast = this.handleShowToast.bind(this);
    this.handleSaveToast = this.handleSaveToast.bind(this);
    this.handleErrToast = this.handleErrToast.bind(this);
    this.componentDidMount = this.componentDidMount.bind(this);
    this.toDateValidation = this.toDateValidation.bind(this);
    this.handleSearchChange = this.handleSearchChange.bind(this);
    this.handleCurrDateToast = this.handleCurrDateToast.bind(this);
    this.onSelect = this.onSelect.bind(this);
    this.onRemove = this.onRemove.bind(this);
    this.handleValToast = this.handleValToast.bind(this);
    this.handlDeleteToast = this.handleDeleteToast.bind(this);
    this.selectedValue = this.setSelectedValue.bind(this);
    this.saveData = this.saveData.bind(this);
    // this.closeRegForm=this.closeRegForm.bind(this)
  }

  closeRegForm = () => {
    window.location.reload();
  };

  onChange(e) {
    alert();
    let files = e.target.files;
    console.warn("data files", files);
  }

  deleteRow(eventNumber, index) {
    const data = localStorage.getItem("token");
    axios
      .delete(apiUrlEvent + `event/v1/deleteData/${eventNumber}`, {
        headers: { Authorization: data },
      })
      .then((response) => {
        let temp = [...this.state.rows];
        temp.splice(index, 1);
        this.setState({ rows: temp });
      });

    this.handleDeleteToast(eventNumber, index);
  }

  fileSelectedHandler = (e) => {
    // e.preventDefault();
    const formData = new FormData();
    const file = e.target.files[0];
    formData.append("file", file);
    const data = localStorage.getItem("token");
    axios.defaults.headers.common["Authorization"] = data;
    axios
      .post(apiUrlEvent + "event/v1/uploadDataToDb", formData)
      .then((response) => {
        alert("File Uploaded Successfully");
        this.setState({
          rows: response.data.response.notificationResponseList,
        });
      });
  };

  connect() {
    var sock = new SockJS(apiUrlEvent + "event/ws-message");
    let stompClient = Stomp.over(sock);
    sock.onopen = function () {
      console.log("open");
    };
    let self = this;
    stompClient.connect({}, function (frame) {
      console.log("Connected 2: " + frame);
      stompClient.subscribe("/topic/messages", function (data) {
        console.log(data.body);
        //you can execute any function here
        self.getdt();
        // self.playSound();
        // self.showEventToast(JSON.parse(data.body));
        self.notificationPer(JSON.parse(data.body));
      });
    });
  }
  toastClosed(eventId) {
    const data = localStorage.getItem("token");
    axios
      .get(apiUrlEvent + `event/v1/updateEventSeen/${eventId}`, {
        headers: { Authorization: data },
      })
      .then((response) => {
        console.log("seen update response ", response);
      });
  }

  playSound() {
    const audio = new Audio(notification);
    audio.play();
  }

  CustomToastMsg(data) {
    return (
      <div>
        <div>
          <span className="font-weight-bold">Type :</span>{" "}
          {data.eventTypeValue == undefined
            ? data.serviceTypeName
            : data.eventTypeValue}
        </div>
        <div>
          <span className="font-weight-bold">Description :</span>{" "}
          {data.eventDescription}
        </div>
        <div>
          <span className="font-weight-bold">Alert Id :</span>{" "}
          {data.msgNumber == undefined ? data.alertId : data.msgNumber}
        </div>
        <div>
          <span className="font-weight-bold">Machine Name :</span> Not Found
        </div>
        <div>
          <span className="font-weight-bold">Hall :</span> Not Found
        </div>
      </div>
    );
  }

  showEventToast(msg) {
    toast(this.CustomToastMsg(msg), {
      position: "top-right",
      autoClose: false,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      onClose: () => this.toastClosed(msg.eventId),
    });
  }

  notificationPer(msg) {
    let self = this;
    if (Notification.permission === "granted") {
      this.showNotification(msg);
    } else {
      // request permission from user
      Notification.requestPermission()
        .then(function (p) {
          if (p === "granted") {
            self.showNotification(msg);
          } else {
            console.log("User blocked notifications.");
          }
        })
        .catch(function (err) {
          console.error(err);
        });
    }
  }

  showNotification(data) {
    let type =
      data.eventTypeValue == undefined
        ? data.serviceTypeName
        : data.eventTypeValue;
    let description = data.eventDescription.substring(0, 25) + "....";
    let alertId = data.msgNumber == undefined ? data.alertId : data.msgNumber;
    let eventId = data.eventId;
    let machine = data.machine;
    let hallNumber = data.hallNumber;

    let notification = new Notification("SPIE", {
      body:
        "Type : " +
        type +
        "\n" +
        "Description : " +
        description +
        "\n" +
        "Alert Id :" +
        alertId +
        ", Hall : " +
        hallNumber +
        "\n" +
        "Machine Name : " +
        machine,
      tag: eventId,
    });

    this.state.notificationArr.push(notification);

    // console.log(this.state.notificationArr);
    // setTimeout(() => notification.close(), 5*1000);

    notification.onclick = (e) => {
      this.toastClosed(data.eventId);
    };

    // notification.onclose = (e) => {
    //   this.toastClosed(data.eventId);
    // };
  }

  removeAllNotification = () => {
    // let flag = false;
    // for(var i=0; i<this.state.notificationArr.length;i++){
    //   this.state.notificationArr[i].close();
    //   flag = true;                //remove them all
    // }
    // if(flag){
    //   toast.success("Successfully Close!");
    // }

    const data = localStorage.getItem("token");
    axios
      .get(apiUrlEvent + "event/v1/updateAllEventSeen", {
        headers: { Authorization: data },
      })
      .then((response) => {
        let flag = false;
        for (var i = 0; i < this.state.notificationArr.length; i++) {
          this.state.notificationArr[i].close();
          flag = true; //remove them all
        }
        if (flag) {
          toast.success("Successfully Close!");
        }
      });
  };

  componentWillMount() {
    const data = localStorage.getItem("token");
    axios
      .get(apiUrlEvent + "event/v1/getExcelDataFromDb", {
        headers: { Authorization: data },
      })
      .then((response) => {
        this.setState({
          rows: response.data.response.notificationResponseList,
        });
        this.setState({
          originalData: response.data.response.notificationResponseList,
        });

        let playFlag = false;
        let notificationData = response.data.response.notificationResponseList;

        for (let i = notificationData.length - 1; i >= 0; i--) {
          if (notificationData[i].seenFlag == 0) {
            // this.showEventToast(notificationData[i]);
            this.notificationPer(notificationData[i]);
            playFlag = true;
          }
        }
        // if (playFlag) {
        //   this.playSound();
        // }
      });
    // } else {
    //   this.props.history.push("/admin/vendor");
    // }

    this.setState({
      addDiv: false,
      gridDiv: true,
      savebutton: false,
      editbutton: false,
    });
    this.connect();
    this.getbeaconList();
    this.getHallInfo();
  }

  states = {
    selectedFile: null,
  };
  openAddDiv() {
    this.getbeaconList();
    this.getServiceTypeList();

    this.setState({
      addDiv: true,
      gridDiv: false,
      savebutton: true,
      editbutton: false,
    });

    this.sts = "save";
  }

  editRow(data) {
    this.getbeaconList();
    this.getServiceTypeList();
    this.onSelect(data.beaconList);
    this.state.selectedValue = data.beaconList;

    this.setState({
      addDiv: true,
      gridDiv: false,
      savebutton: false,
      editbutton: true,
    });
    this.state.sts = "edit";
    this.state.Ereignis = data.eventNumber;
    this.state.Kategorie = data.category;
    this.state.BeaconUniqueId = data.beaconUniqueId;
    this.state.Ereignisart = data.eventType;

    this.state.Beschreibung = data.eventDescription;
    if (null === data.periodFrom) {
      this.state.Dauervon = "";
    } else {
      this.state.Dauervon = new Date(data.periodFrom);
    }

    if (data.periodTo === undefined) {
      this.state.Dauerbis = "";
    } else {
      this.state.Dauerbis = new Date(data.periodTo);
    }
    this.state.Anzeigenab = data.displayFrom;

    this.state.Priorität = data.eventPriority;
    this.state.meldungsnummer = data.msgNumber;
    this.state.titel = data.title;
    this.state.Ansprechpartner = data.pointOfContact;
    this.state.AnsprechpartnerTe = data.pointOfContactTelephone;
    this.state.Status = data.eventStatus;

    this.state.AnzeigenfürRolle = data.displayForRoles;
    this.state.Rechtssicherheitsrelevant =
      data.legalComplianceRelevant === "null"
        ? "Ja"
        : data.legalComplianceRelevant;
    this.state.Einschränkung = data.restriction;

    this.state.GebäudeNr = data.buildingInfoDto.buildNumber;
    this.state.Bereich = data.buildingInfoDto.area;
    this.state.Liegenschaftsnr = data.buildingInfoDto.propertyNumber;
    this.state.Etage = data.buildingInfoDto.floor;

    this.setState({ disabled: !this.state.disabled });
  }

  async saveData(event) {
    this.state.selectedValue.map((item) => {
      this.state.beaconInfoList.push(item.beaconId);
      return null;
    });

    if (this.state.beaconInfoList.length > 0) {
      if (this.state.Ereignisart === undefined) {
        this.state.Ereignisart = "ServiceType1";
      }

      event.preventDefault();
      const postData = {
        msgNumber: this.state.meldungsnummer,
        title: this.state.titel,
        beaconIdList: this.state.beaconInfoList,
        eventNumber: this.state.Ereignis,
        category: this.state.Kategorie,
        beaconUniqueId: this.state.BeaconUniqueId,
        eventType: this.state.Ereignisart,
        eventDescription: this.state.Beschreibung,
        eventPriority: this.state.Priorität,
        pointOfContact: this.state.Ansprechpartner,
        pointOfContactTelephone: this.state.AnsprechpartnerTe,
        displayForRoles: this.state.AnzeigenfürRolle,
        periodFrom: this.state.Dauervon,
        periodTo: this.state.Dauerbis,
        eventStatus: this.state.Status,
        displayFrom: this.state.Anzeigenab,

        legalComplianceRelevant: this.state.Rechtssicherheitsrelevant,
        restriction: this.state.Einschränkung,

        buildingInfoDto: {
          buildNumber: this.state.GebäudeNr,
          area: this.state.Bereich,
          propertyNumber: this.state.Liegenschaftsnr,
          floor: this.state.Etage,
        },
      };

      await axios
        .post(apiUrlEvent + "event/v1/insertNewEvent", postData, {
          headers: { Authorization: localStorage.getItem("token") },
        })
        .then((res) => {
          this.handleSaveToast();
          this.componentDidMount();
          window.location.reload(false);
          this.getdt();
          this.setState({
            addDiv: false,
            gridDiv: true,
            savebutton: false,
            editbutton: false,
          });
        })
        .catch((exception) => {
          this.handleErrToast();
          console.log("Exception", exception);
        });
    } else {
      event.preventDefault();
      this.handleValToast();
    }
  }

  getdt() {
    axios
      .get(apiUrlEvent + "event/v1/getExcelDataFromDb", {
        headers: { Authorization: localStorage.getItem("token") },
      })
      .then((response) => {
        this.setState({
          rows: response.data.response.notificationResponseList,
        });
        this.setState({
          originalData: response.data.response.notificationResponseList,
        });
      });
  }

  getbeaconList() {
    axios
      .get(apiUrlEvent + "event/v1/getBeaconList", {
        headers: { Authorization: localStorage.getItem("token") },
      })
      .then((response) => {
        this.setState({ beaconList: response.data.response });
      });
  }

  getServiceTypeList() {
    axios
      .get(apiUrlEvent + "event/v1/getServiceTypeList", {
        headers: { Authorization: localStorage.getItem("token") },
      })
      .then((response) => {
        this.setState({ serviceTypeList: response.data.response });
      });
  }

  disablePastDt(current) {
    alert(this.state.currentDate);
    return current.isAfter(this.state.currentDate);
  }

  handleChange(date) {
    this.setState({
      Dauervon: date,
    });
    this.dateValidation(date);
  }

  dateValidation(date) {
    if (new Date(date).getTime() < new Date(this.state.currentDateTime)) {
      this.setState({
        Dauervon: "",
      });
      this.handleCurrDateToast();
    }
    if (this.state.Dauerbis !== null) {
      if (new Date(date).getTime() >= new Date(this.state.Dauerbis).getTime()) {
        this.setState({
          Dauervon: "",
        });

        this.handleShowToast();

        // toast.info('GeeksForGeeks') ;
      }
    }
  }

  onRemove(selectedList, removedItem) {
    console.log("on remove ",selectedList);
    this.state.selectedValue = selectedList;
  }

  onSelect(selectedList) {
    this.state.selectedValue = selectedList;
    console.log("on select ", selectedList);
    selectedList.map((ele) => {
      this.setState({ beaconUniqueId: ele.name }, () => {
        this.beaconFilter();
        console.log(this.state);
      });
    });
  }

  beaconFilter() {
    let temp = this.state.originalData;
    if (this.state.selectedValues !== undefined && this.state.selectedValues.length > 0) {
      let selectedList = [];
      this.state.selectedValues.map((ele) => {
        selectedList.push(ele.name);
      });
      let beaconFilter = [];
      let data = [];
      selectedList.map((beacon) => {
        data = temp.filter((value) => {
          return value.beaconIdStr === undefined
            ? value.beaconUniqueId
            : value.beaconIdStr.includes(beacon);
        });
        beaconFilter = beaconFilter.concat(data);
      })
      temp = beaconFilter;      
    }
    console.log("beacon filter ", temp);
    if (this.state.Priorität !== undefined && this.state.Priorität !== "") {
      temp = temp.filter((value) => {
        return value.eventPriority === undefined
          ? value.eventPriority
          : value.eventPriority
              .toLowerCase()
              .includes(this.state.Priorität.toLowerCase());
      });
    }
    console.log("priority filter ", temp);
    if (this.state.Dauervon !== undefined && this.state.Dauervon !== "") {
      temp = temp.filter((value) => {        
        return value.periodFromStr === undefined
          ? value.periodFromStr
          : new Date(value.periodFrom).getTime() >= (
            new Date(this.state.Dauervon).getTime()
            );
      });
    }
    console.log("period from  filter ", temp);
    if (this.state.Dauerbis !== undefined && this.state.Dauerbis !== "") {
      temp = temp.filter((value) => {
        return value.periodToStr === undefined
          ? value.periodToStr
          : new Date(value.periodTo).getTime() <= (
            new Date(this.state.Dauerbis).getTime()
            );
      });
    }
    console.log("period to  filter ", temp);
    if (this.state.hallName !== undefined && this.state.hallName !== "") {
      temp = temp.filter((value) => {
        return value.hallNumber === undefined
          ? value.hallNumber 
          : value.hallNumber.includes(this.state.hallName);
      });
    }
    console.log("hall number  filter ", temp);
    this.setState({ rows: temp });
  }

  handleSearchChange(value) {
    this.setState({ searchInput: value }, () => {
      this.globalSearch();
    });
  }

  globalSearch() {
    if (this.state.searchInput !== "") {
      this.state.filteredData = this.state.originalData.filter((value) => {
        return value.beaconIdStr === undefined
          ? value.beaconIdStr
          : value.beaconIdStr
              .toLowerCase()
              .includes(this.state.searchInput.toLowerCase()) ||
            value.msgNumber === undefined
          ? value.msgNumber
          : value.msgNumber
              .toLowerCase()
              .includes(this.state.searchInput.toLowerCase()) ||
            value.title === undefined
          ? value.title
          : value.title
              .toLowerCase()
              .includes(this.state.searchInput.toLowerCase()) ||
            value.eventDescription === undefined
          ? value.eventDescription
          : value.eventDescription
              .toLowerCase()
              .includes(this.state.searchInput.toLowerCase()) ||
            value.eventPriority === undefined
          ? value.eventPriority
          : value.eventPriority
              .toLowerCase()
              .includes(this.state.searchInput.toLowerCase()) ||
            value.periodFromStr === undefined
          ? value.periodFromStr
          : value.periodFromStr.includes(
              this.state.searchInput.toLowerCase()
            ) || value.periodToStr === undefined
          ? value.periodToStr
          : value.periodToStr.includes(this.state.searchInput.toLowerCase()) ||
            value.pointOfContact === undefined
          ? value.pointOfContact
          : value.pointOfContact
              .toLowerCase()
              .includes(this.state.searchInput.toLowerCase()) ||
            value.eventTypeValue === undefined
          ? value.eventTypeValue
          : value.eventTypeValue
              .toLowerCase()
              .includes(this.state.searchInput.toLowerCase()) ||
            value.pointOfContactTelephone === undefined
          ? value.pointOfContactTelephone
          : value.pointOfContactTelephone
              .toLowerCase()
              .includes(this.state.searchInput.toLowerCase()) ||
            value.buildingInfoDto.buildNumber === undefined
          ? value.buildingInfoDto.buildNumber
          : value.buildingInfoDto.buildNumber
              .toLowerCase()
              .includes(this.state.searchInput.toLowerCase()) ||
            value.buildingInfoDto.area === undefined
          ? value.buildingInfoDto.area
          : value.buildingInfoDto.area
              .toLowerCase()
              .includes(this.state.searchInput.toLowerCase()) ||
            value.buildingInfoDto.floor === undefined
          ? value.buildingInfoDto.floor
          : value.buildingInfoDto.floor
              .toLowerCase()
              .includes(this.state.searchInput.toLowerCase());
      });
      this.setState({ rows: this.state.filteredData });
    } else {
      this.setState({ rows: this.state.originalData });
    }
  }

  handleToDateChange(date) {
    this.setState({
      Dauerbis: date,
    });
    this.toDateValidation(date);
  }

  toDateValidation(date) {
    if (this.state.Dauervon === undefined || this.state.Dauervon === "") {
      if (new Date(date).getTime() < new Date(this.state.currentDateTime)) {
        this.setState({
          Dauerbis: "",
        });
        this.handleCurrDateToast();
      }
    }
    if (this.state.Dauervon !== null) {
      if (
        new Date(date).getTime() === new Date(this.state.Dauervon).getTime()
      ) {
        this.setState({
          Dauerbis: "",
        });

        this.handleShowToast();

        // toast.info('GeeksForGeeks') ;
      }

      if (new Date(date) < new Date(this.state.Dauervon)) {
        this.setState({
          Dauerbis: "",
        });
        this.handleShowToast();

        // this.handleShowToast();

        // toast.info('GeeksForGeeks') ;
      }
    }
  }

  async updateData(event) {
    this.state.selectedValue.map((item) => {
      this.state.beaconInfoList.push(item.beaconId);
      return null;
    });
    if (this.state.beaconInfoList.length > 0) {
      if (!this.state.Ansprechpartner) {
        this.setState({ error: "" });
        return;
      }
      if (!this.state.AnsprechpartnerTe) {
        this.setState({ error: "" });
        return;
      }
      if (!this.state.Beschreibung) {
        this.setState({ error: "" });
        return;
      }
      if (!this.state.Dauervon) {
        this.setState({ error: "" });
        return;
      }

      if (!this.state.GebäudeNr) {
        this.setState({ error: "" });
        return;
      }
      if (!this.state.Bereich) {
        this.setState({ error: "" });
        return;
      }

      if (!this.state.Etage) {
        this.setState({ error: "" });
        return;
      }

      if (!this.state.titel) {
        this.setState({ error: "" });
        return;
      }
      if (!this.state.meldungsnummer) {
        this.setState({ error: "" });
        return;
      }
      event.preventDefault();
      const postData = {
        msgNumber: this.state.meldungsnummer,
        title: this.state.titel,
        beaconIdList: this.state.beaconInfoList,
        eventNumber: this.state.Ereignis,
        category: this.state.Kategorie,
        beaconUniqueId: this.state.BeaconUniqueId,
        eventType: this.state.Ereignisart,
        eventDescription: this.state.Beschreibung,
        eventPriority: this.state.Priorität,
        pointOfContact: this.state.Ansprechpartner,
        pointOfContactTelephone: this.state.AnsprechpartnerTe,
        displayForRoles: this.state.AnzeigenfürRolle,
        periodFrom: this.state.Dauervon,
        periodTo: this.state.Dauerbis,
        eventStatus: this.state.Status,
        displayFrom: this.state.Anzeigenab,

        legalComplianceRelevant: this.state.Rechtssicherheitsrelevant,
        restriction: this.state.Einschränkung,
        buildingInfoDto: {
          buildNumber: this.state.GebäudeNr,
          area: this.state.Bereich,
          propertyNumber: this.state.Liegenschaftsnr,
          floor: this.state.Etage,
        },
      };

      await axios
        .put(apiUrlEvent + `event/v1/updateData`, postData, {
          headers: { Authorization: localStorage.getItem("token") },
        })
        .then((res) => {
          this.handleSaveToast();
          window.location.reload(false);
          this.readrt();
          this.setState({
            addDiv: false,
            gridDiv: true,
            savebutton: false,
            editbutton: false,
          });
        })
        .catch((error) => {
          this.handleErrToast();
          console.log(error);
        });
    } else {
      event.preventDefault();
      this.handleValToast();
    }
  }

  readrt() {
    axios
      .get(apiUrlEvent + "event/v1/getExcelDataFromDb", {
        headers: { Authorization: localStorage.getItem("token") },
      })
      .then((response) => {
        this.setState({
          rows: response.data.response.notificationResponseList,
        });
        this.setState({
          originalData: response.data.response.notificationResponseList,
        });
      });
  }

  handleShowToast() {
    this.setState({ showToast: !this.state.showToast });
  }

  handleCurrDateToast() {
    this.setState({ showCurrToast: !this.state.showCurrToast });
  }

  handleSaveToast() {
    this.setState({ showSaveToast: !this.state.showSaveToast });
  }

  handleValToast() {
    this.setState({ showValToast: !this.state.showValToast });
  }

  setSelectedValue(selectedValues) {
    console.log("in set select  ", selectedValues);
    this.setState({ selectedValue: selectedValues });
  }

  componentDidMount() {
    setTimeout(
      function () {
        //Start the timer
        this.setState({ render: true }); //After 1 second, set render to true
      }.bind(this),
      2000
    );
  }

  handleErrToast() {
    this.setState({ showErrToast: !this.state.showErrToast });
  }

  closeToast() {
    this.setState({ showToast: false });
  }

  handleDeleteToast(eventNumber, index) {
    this.setState({ rowEventNumber: eventNumber, rowIndex: index });
    this.setState({ showDeleteToast: !this.state.showDeleteToast });
  }

  handleChangeSelect(e) {
    this.state({
      selectedValue: Array.isArray(e) ? e.map((x) => x.value) : [],
    });
  }

  isShowPopup(status) {
    this.setState({ showModalPopup: status });
    this.setState({ addDiv: false });
    this.setState({ gridDiv: false });
  }

  getHallInfo() {
    axios.get(apiUrlEvent + 'event/v1/getHallInfo', { headers: { "Authorization": localStorage.getItem("token") } })
      .then(response => {
        this.setState({ hallInfoList: response.data.response })
      })

  }

  handleSubmit(event) {
    this.beaconFilter(); 
    this.setState({ showModalPopup: false });
    this.setState({ addDiv: false });
    this.setState({ gridDiv: true });
  }

  resetFilter() {
    // this.setState({ showLoader: true});
    this.setState({ rows: this.state.originalData });    
    this.setState({ searchPrioritat: ''});
    this.setState({ titel: '' });
    this.setState({ Priorität: ''});
    this.setState({ selectedValues: '' }); 
    this.setState({ Dauerbis: ''});
    this.setState({ Dauervon: '' });
    this.setState({ hallName: '' }); 
    // this.setState({ showLoader: false});
  }

  handlePriorityChange(value) {
    this.setState({ searchPrioritat: value }, () => {
      this.prioritySearch();
      console.log(this.state);
    });
  }

  prioritySearch() {
    let temp = this.state.originalData;
    if(this.state.titleSearchData.length > 0 && this.state.searchTitel !== undefined){
      temp = this.state.titleSearchData;
    }
    if (this.state.searchPrioritat !== "") {
      temp = temp.filter(value => {
        return (
          value.eventPriority === undefined ? value.eventPriority : value.eventPriority.toLowerCase().includes(this.state.searchPrioritat.toLowerCase())

        )
      })
      this.setState({ prioritySearchData: temp });
      this.setState({ rows: temp });
    } else if(this.state.titleSearchData.length >0){
      this.setState({ rows: this.state.titleSearchData });
    } else {
      this.setState({ rows: this.state.originalData });
    }
  }

  handleTitelChange(value) {
    this.setState({ searchTitel: value }, () => {
      this.titelSearch();
    });
  }

  titelSearch() {
    let temp = this.state.originalData;
    if(this.state.prioritySearchData.length > 0 && this.state.searchPrioritat !== ""){
      temp = this.state.prioritySearchData;
    }
    if (this.state.searchTitel !== "" && this.state.searchTitel !== undefined) {
      temp = temp.filter(value => {
        return (
          value.title === undefined ? value.title : value.title.toLowerCase().includes(this.state.searchTitel.toLowerCase())

        )
      })
      this.setState({ titleSearchData: temp });
      this.setState({ rows: temp });
    } else if(this.state.prioritySearchData.length > 0){
      this.setState({ rows: this.state.prioritySearchData });
    } else {
      this.setState({ rows: this.state.originalData });
    }
  }

  render() {
    return (
      <div className="content">
        <ToastContainer
          newestOnTop
          toastStyle={{ backgroundColor: "#1d8cf8", color: "white" }}
        />
        <Toast
          show={this.state.showErrToast}
          onClose={this.handleErrToast}
          delay={3000}
          autohide
        >
          <Toast.Header>
            <img
              src="holder.js/20x20?text=%20"
              className="rounded mr-2"
              alt=""
            />
            <strong className="mr-auto errClr">Error</strong>
          </Toast.Header>
          <Toast.Body>Exception occured</Toast.Body>
        </Toast>

        <Toast
          show={this.state.showValToast}
          onClose={this.handleValToast}
          delay={3000}
          autohide
        >
          <Toast.Header>
            <img
              src="holder.js/20x20?text=%20"
              className="rounded mr-2"
              alt=""
            />
            <strong className="mr-auto errClr">Error</strong>
          </Toast.Header>
          <Toast.Body>Bitte wählen Sie das Leuchtfeuer</Toast.Body>
        </Toast>

        <Toast
          show={this.state.showToast}
          onClose={this.handleShowToast}
          delay={3000}
          autohide
        >
          <Toast.Header>
            <img
              src="holder.js/20x20?text=%20"
              className="rounded mr-2"
              alt=""
            />
            <strong className="mr-auto errClr">Error</strong>
          </Toast.Header>
          <Toast.Body>Dauervon sollte kleiner als Dauerbis sein</Toast.Body>
        </Toast>

        <Toast
          show={this.state.showCurrToast}
          onClose={this.handleCurrDateToast}
          delay={3000}
          autohide
        >
          <Toast.Header>
            <img
              src="holder.js/20x20?text=%20"
              className="rounded mr-2"
              alt=""
            />
            <strong className="mr-auto errClr">Error</strong>
          </Toast.Header>
          <Toast.Body>
            DateTime sollte größer als currentDatetime sein
          </Toast.Body>
        </Toast>

        <Toast
          show={this.state.showSaveToast}
          onClose={this.handleSaveToast}
          delay={6000}
        >
          <Toast.Header>
            <img
              src="holder.js/25x25?text=%25"
              className="rounded mr-2 "
              alt=""
            />
            <strong className="mr-auto updateClr">Message </strong>
          </Toast.Header>
          <Toast.Body>Daten erfolgreich gespeichert</Toast.Body>
        </Toast>

        <Toast
          show={this.state.showDeleteToast}
          onClose={() =>
            this.handleDeleteToast(
              this.state.rowEventNumber,
              this.state.rowIndex
            )
          }
          delay={3000}
        >
          <Toast.Header>
            <img
              src="holder.js/20x20?text=%20"
              className="rounded mr-2"
              alt=""
            />

            <strong className="mr-auto errClr">Achtung</strong>
          </Toast.Header>
          <Toast.Body>
            <p>Sind Sie sicher, dass Sie diese Meldung löschen möchten?</p>
            <button
              className="yes btn btn-primary"
              onClick={(e) =>
                this.deleteRow(this.state.rowEventNumber, this.state.rowIndex)
              }
            >
              Ja
            </button>
            <button
              className="no btn btn-primary"
              onClick={(e) =>
                this.handleDeleteToast(
                  this.state.rowEventNumber,
                  this.state.rowIndex
                )
              }
            >
              Nein
            </button>
          </Toast.Body>
        </Toast>

        {this.state.gridDiv ? (
          <>
            <div>
              {/* <Loader 
                type="spinner-circle"
                bgColor={"#1d8cf8"}
                color={"#FFFFFF"}
                size={50}
              /> */}
            </div>
            <Row>
              <Col lg="12">
                <Card className="card-chart">
                  <CardHeader>
                    <h3 className="title m-b-0">DATEN MANAGEMENT</h3>
                  </CardHeader>
                  <CardBody>
                    <Form>
                      <div className="col-lg-12"></div>
                    </Form>
                  </CardBody>
                </Card>
                <SearchField
                  placeholder="Suche..."
                  onChange={(value) => this.handleSearchChange(value)}
                  classNames="test-class"
                />
                <button
                  className="btn btn-sm btn-info"
                  onClick={() => this.isShowPopup(true)}
                  data-toggle="tooltip"
                  data-placement="top"
                  title="Show Filters"
                >
                  Filters
                </button>
                <button
                  className="btn btn-sm btn-info"
                  onClick={() => this.resetFilter()}
                  data-toggle="tooltip"
                  data-placement="top"
                  title="Remove Filters"
                >
                  Reset
                </button>
                <button
                  className="btn btn-sm btn-info float-right"
                  onClick={() => this.removeAllNotification()}
                  data-toggle="tooltip"
                  data-placement="top"
                  title="Close All Notifications"
                >
                  Close All
                </button>
                <Card className="card-chart margin-bottom0">
                  <CardBody>
                    <div className="daten-management-box">
                      <Table className="tablesorter upload-daten-management">
                        <thead
                          className="text-primary sticky-thead"
                          style={{ width: "fit-content" }}
                        >
                          <tr>
                            {/*<th>Ereignis Nummer</th>*/}
                            <th>Alert ID</th>
                            <th>Count</th>
                            <th>
                              Priorität
                              <select
                                type="select"
                                name="Priorität"
                                className="form-control"
                                value={this.state.searchPrioritat}
                                onSelect={() => this.onSelect.bind(this)}
                                onChange={(e) =>
                                  this.handlePriorityChange(e.target.value)
                                }
                                style={{ width: "fit-content" }}
                              >
                                <option value="" style={{ color: "black" }}>
                                  wählen Priorität
                                </option>
                                <option value="hoch" style={{ color: "black" }}>
                                  hoch
                                </option>
                                <option
                                  value="niedrig"
                                  style={{ color: "black" }}
                                >
                                  niedrig
                                </option>
                              </select>
                            </th>
                            <th>Beacon ID</th>
                            <th>
                              Titel
                              <input
                                type="text"
                                name="titel"
                                placeholder="Titel"
                                className="form-control"
                                // value={this.state.titel}
                                onChange={(e) =>
                                  this.handleTitelChange(e.target.value)
                                }
                                style={{ width: "fit-content" }}
                              ></input>
                            </th>
                            <th>Hall Number</th>
                            <th>Beschreibung </th>
                            <th>Dienstleistung </th>
                            <th>Startdatum</th>
                            <th>Geplantes Enddatum</th>
                            <th>Liegenschaft </th>
                            <th>Gebäudenummer </th>
                            <th>Etage </th>
                            <th>Ansprechpartner SPIE</th>
                            <th>Ansprechpartner SPIE - Telefonnr</th>
                            <th>Bearbeiten</th>
                            <th>Löschen</th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.rows.map((data, index) => (
                            <tr key={index}>
                              {/* <td>{data.eventNumber}</td> */}
                              <td>{data.msgNumber}</td>
                              <td>{data.eventCount}</td>
                              <td>{data.eventPriority}</td>
                              <td>{data.beaconIdStr}</td>
                              <td>{data.title}</td>
                              <td>{data.hallNumber}</td>
                              <td>{data.eventDescription}</td>
                              <td>{data.eventTypeValue}</td>
                              <td>{data.periodFromStr}</td>
                              <td>{data.periodToStr}</td>
                              <td>
                                {data.buildingInfoDto
                                  ? data.buildingInfoDto.area
                                  : ""}
                              </td>
                              <td>
                                {data.buildingInfoDto
                                  ? data.buildingInfoDto.buildNumber
                                  : ""}
                              </td>
                              <td>
                                {data.buildingInfoDto
                                  ? data.buildingInfoDto.floor
                                  : ""}
                              </td>
                              <td>{data.pointOfContact}</td>
                              <td>{data.pointOfContactTelephone}</td>
                              <td className="icon-button">
                                <span
                                  className="edit icon-box"
                                  onClick={() => this.editRow(data)}
                                >
                                  <img
                                    alt="..."
                                    src={require("assets/img/edit.png")}
                                    style={{
                                      backgroundColor: "white",
                                      height: "40px",
                                      width: "45px",
                                    }}
                                  />
                                </span>
                              </td>

                              <td className="icon-button">
                                <span
                                  className="delete icon-box"
                                  onClick={(e) =>
                                    this.handleDeleteToast(
                                      data.eventNumber,
                                      index
                                    )
                                  }
                                >
                                  <img
                                    alt="..."
                                    src={require("assets/img/delte.png")}
                                    style={{
                                      backgroundColor: "white",
                                      height: "40px",
                                      width: "40px",
                                    }}
                                  />
                                </span>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </>
        ) : null}

        {this.state.addDiv ? (
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <form
                    onSubmit={(event) =>
                      this.state.sts === "edit"
                        ? this.updateData(event)
                        : this.saveData(event)
                    }
                  >
                    <Row>
                      <Col className="pr-md-1 daten-head-col" md="12">
                        <label>
                          <b>Meldungsinformationen</b>
                        </label>
                      </Col>
                    </Row>
                    <Row>
                      <Col className="pr-md-1 daten-field-col" md="4">
                        <FormGroup>
                          <label>Alert ID *</label>
                          <input
                            required
                            type="text"
                            name="meldungsnummer"
                            value={this.state.meldungsnummer}
                            className="form-control"
                            placeholder="Alert ID"
                            onChange={(event) =>
                              this.setState({
                                meldungsnummer: event.target.value,
                              })
                            }
                          ></input>
                        </FormGroup>
                      </Col>
                      <Col className="pr-md-1 daten-field-col" md="4">
                        <FormGroup>
                          <label>Priorität *</label>

                          {/* <input type="text" name="Priorität" placeholder="Priorität" className="form-control" value={this.state.Priorität}
                              onChange={event => this.setState({ Priorität: event.target.value })}></input> */}
                              
                              <select type="select" name="Priorität" className="form-control" value={this.state.Priorität}
                              onChange={event => { this.setState({ Priorität: event.target.value }) }} required>
                              <option value="" style={{ color: "black" }}>wählen Priorität</option>
                              <option value="hoch" style={{ color: "black" }}> hoch</option>
                              <option value="niedrig" style={{ color: "black" }}>niedrig</option>
                              </select>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col className="pr-md-1 daten-field-col" md="4">
                        <FormGroup>
                          <label>Beacon ID *</label>
                          <Multiselect
                              placeholder="Beacon ID" className="form-control"
                              options={this.state.beaconList} // Options to display in the dropdown
                              onSelect={this.onSelect} // Function will trigger on select event
                              onRemove={this.onRemove} // Function will trigger on remove event
                              selectedValues={this.state.selectedValue}
                              displayValue="name" // Property name to display in the dropdown options
                              hidePlaceholder="true"
                              showCheckbox="true"
                              closeIcon="../src/assets/images/cancel.png"
                              avoidHighlightFirstOption="true"

                            />
                        </FormGroup>
                      </Col>
                      <Col className="pr-md-1 daten-field-col" md="4">
                        <FormGroup>
                          <label>Titel *</label>
                          <input required type="text" name="titel" placeholder="Titel" className="form-control" value={this.state.titel}
                              onChange={event => this.setState({ titel: event.target.value })}></input>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col className="pr-md-1 daten-field-col" md="4">
                        <FormGroup>
                          <label>Beschreibung *</label>
                          <input
                            required
                            type="text"
                            name="Beschreibung"
                            placeholder="Beschreibung"
                            className="form-control"
                            value={this.state.Beschreibung}
                            onChange={(event) =>
                              this.setState({
                                Beschreibung: event.target.value,
                              })
                            }
                          ></input>
                        </FormGroup>
                      </Col>
                      <Col className="pr-md-1 daten-field-col" md="4">
                        <FormGroup>
                          <label>Dienstleistung *</label>
                          {/* <input required type="text" name="Ereignisart" placeholder="Dienstleistung" className="form-control" value={this.state.Ereignisart}
                              onChange={event => this.setState({ Ereignisart: event.target.value })}></input> */}
                          <select
                            required
                            type="select"
                            name="Dienstleistung"
                            className="form-control"
                            value={this.state.Ereignisart}
                            onChange={(event) => {
                              this.setState({
                                Ereignisart: event.target.value,
                              });
                            }}
                          >
                            {/* <option value="ServiceType1" style={{ color: "black" }}>Service Type 1</option>
                              <option value="ServiceType2" style={{ color: "black" }}>Service Type 2</option>
                              <option value="ServiceType3" style={{ color: "black" }}>Service Type 3</option>
							                <option value="ServiceType4" style={{ color: "black" }}>Service Type 4</option>
							                <option value="ServiceType5" style={{ color: "black" }}>Service Type 5</option> */}
                            {this.state.serviceTypeList.map((e) => {
                              return (
                                <option
                                  value={e.key}
                                  style={{ color: "black" }}
                                >
                                  {e.value}{" "}
                                </option>
                              );
                            })}
                          </select>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col
                        className="pr-md-1 daten-field-col margin-bottom-10"
                        md="4"
                      >
                        <FormGroup>
                          <label>Startdatum *</label>
                          <DatePicker
                            className="form-control"
                            required
                            value={this.state.Dauervon}
                            selected={this.state.Dauervon}
                            onChange={this.handleChange}
                            //showTimeInput
                            showTimeSelect
                            //  showTi
                            placeholderText="Startdatum"
                            minDate={new Date()}
                            timeFormat="HH:mm"
                            timeCaption="Time"
                            timeIntervals={5}
                            dateFormat="dd.MM.yyyy, HH:mm"
                          />
                        </FormGroup>
                      </Col>
                      <Col
                        className="pr-md-1 daten-field-col margin-bottom-10"
                        md="4"
                      >
                        <FormGroup>
                          <label>Geplantes Enddatum</label>
                          <DatePicker
                            className="form-control"
                            value={this.state.Dauerbis}
                            selected={this.state.Dauerbis}
                            onChange={(value) => {
                              this.handleToDateChange(value);
                            }}
                            minDate={new Date(this.state.Dauervon)}
                            showTimeSelect
                            placeholderText="Geplantes Enddatum"
                            timeFormat="HH:mm"
                            timeCaption="Time"
                            timeIntervals={5}
                            dateFormat="dd.MM.yyyy, HH:mm"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col className="pr-md-1 daten-head-col" md="12">
                        <label>
                          <b>Gebäudeinformation</b>
                        </label>
                      </Col>
                    </Row>
                    <Row>
                      <Col className="pr-md-1 daten-field-col" md="4">
                        <FormGroup>
                          <label>Liegenschaft *</label>
                          <input
                            type="text"
                            name="Bereich"
                            placeholder="Liegenschaft"
                            className="form-control"
                            required
                            value={this.state.Bereich}
                            onChange={(event) =>
                              this.setState({ Bereich: event.target.value })
                            }
                          ></input>
                        </FormGroup>
                      </Col>
                      <Col className="pr-md-1 daten-field-col" md="4">
                        <FormGroup>
                          <label>Gebäudenummer *</label>
                          <input
                            type="text"
                            name="Gebäudenummer"
                            placeholder="Gebäudenummer"
                            className="form-control"
                            required
                            value={this.state.GebäudeNr}
                            onChange={(event) =>
                              this.setState({ GebäudeNr: event.target.value })
                            }
                          ></input>
                        </FormGroup>
                      </Col>
                      <Col className="pr-md-1 daten-field-col" md="4">
                        <FormGroup>
                          <label>Etage *</label>
                          <input
                            type="text"
                            name="Etage"
                            placeholder="Etage"
                            className="form-control"
                            required
                            value={this.state.Etage}
                            onChange={(event) =>
                              this.setState({ Etage: event.target.value })
                            }
                          ></input>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col className="pr-md-1 daten-head-col" md="12">
                        <label>
                          <b> Kontaktinformationen</b>
                        </label>
                      </Col>
                    </Row>
                    <Row>
                      <Col className="pr-md-1 daten-field-col" md="4">
                        <FormGroup>
                          <label>Ansprechpartner SPIE *</label>
                          <input
                            required
                            type="text"
                            name="Ansprechpartner SPIE"
                            placeholder="Ansprechpartner"
                            className="form-control"
                            value={this.state.Ansprechpartner}
                            onChange={(event) =>
                              this.setState({
                                Ansprechpartner: event.target.value,
                              })
                            }
                          ></input>
                        </FormGroup>
                      </Col>

                      <Col className="pr-md-1 daten-field-col" md="4">
                        <FormGroup>
                          <label>Ansprechpartner SPIE - Telefonnr *</label>
                          <input
                            required
                            type="text"
                            name="Ansprechpartner Te"
                            placeholder="Ansprechpartner SPIE - Telefonnr"
                            className="form-control"
                            value={this.state.AnsprechpartnerTe}
                            onChange={(event) =>
                              this.setState({
                                AnsprechpartnerTe: event.target.value,
                              })
                            }
                          ></input>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row></Row>

                    <div
                      style={{ marginTop: 20 }}
                      className="d-flex flex-row-reverse"
                    >
                      {this.state.editbutton ? (
                        <Button
                          className="btn-fill"
                          color="Success"
                          type="submit"
                          onClick={(event) => this.updateData(event)}
                        >
                          Speichern
                        </Button>
                      ) : null}
                      {this.state.savebutton ? (
                        <Button
                          className="btn-fill"
                          color="Success"
                          type="submit"
                        >
                          Speichern
                        </Button>
                      ) : null}
                      <Button
                        style={{ marginRight: 10 }}
                        className="btn-fill"
                        color="warning"
                        onClick={this.closeRegForm}
                      >
                        Abbrechen
                      </Button>
                    </div>
                  </form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        ) : null}
        {this.state.showModalPopup ? (
          <Row>
            <Col xs="12">
              <Card
                className="card-chart"
                style={{ height: "calc(100vh - 100px)" }}
              >
                <CardHeader style={{ marginBottom: "10px" }}>
                  <h3 className="title"> Filters </h3>
                </CardHeader>
                <CardBody style={{ marginBottom: "-5px", padding: 20 }}>
                  <Form onSubmit={(event) => this.handleSubmit(event)}>
                    <Row>
                      <br></br>
                      <Col className="pr-md-1" md="6">
                        <FormGroup>
                          Priorität
                          <br />
                          <select
                            type="select"
                            name="Priorität"
                            className="form-control"
                            value={this.state.Priorität}
                            onChange={(event) => {
                              this.setState({ Priorität: event.target.value });
                            }}
                          >
                            <option value="" style={{ color: "black" }}>
                              wählen Priorität
                            </option>
                            <option value="hoch" style={{ color: "black" }}>
                              {" "}
                              hoch
                            </option>
                            <option value="niedrig" style={{ color: "black" }}>
                              niedrig
                            </option>
                          </select>
                          {/* onChange={(e) => this.handlePriorityChange(e.target.value)}    */}
                        </FormGroup>
                      </Col>
                      <Col className="pr-md-1" md="6">
                        <FormGroup>
                          Beacon ID
                          <br />
                          <Select
                            isMulti={true}
                            defaultValue={this.state.selectedValues}
                            onChange={(event) => {
                              this.setState({ selectedValues: event });
                            }}
                            options={this.state.beaconList}
                            getOptionLabel={(ele) => ele.name}
                            getOptionValue={(ele) => ele.name}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <br></br>
                    <Row>
                      <Col className="pr-md-1" md="6">
                        <FormGroup>
                          Startdatum
                          <DatePicker
                            className="form-control"
                            value={this.state.Dauervon}
                            selected={this.state.Dauervon}
                            // onChange={this.handleChange}
                            onChange={(date) => {
                              this.setState({ Dauervon: new Date(date) });
                            }}
                            //showTimeInput
                            showTimeSelect
                            //  showTi
                            placeholderText="Startdatum"
                            // minDate={new Date()}
                            timeFormat="HH:mm"
                            timeCaption="Time"
                            timeIntervals={5}
                            dateFormat="dd.MM.yyyy, HH:mm"
                          />
                        </FormGroup>
                      </Col>
                      <Col className="pr-md-1" md="6">
                        <FormGroup>
                          Geplantes Enddatum
                          <DatePicker
                            className="form-control"
                            value={this.state.Dauerbis}
                            selected={this.state.Dauerbis}
                            onChange={(date) => {
                              this.setState({ Dauerbis: new Date(date) });
                            }}
                            // onChange={(value) => { this.handleToDateChange(value) }}
                            minDate={new Date(this.state.Dauervon)}
                            showTimeSelect
                            placeholderText="Geplantes Enddatum"
                            timeFormat="HH:mm"
                            timeCaption="Time"
                            timeIntervals={5}
                            dateFormat="dd.MM.yyyy, HH:mm"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <br></br>
                    <Row>                    
                      <Col className="pr-md-1" md="6">
                        <FormGroup>
                          Hall
                          <select
                            type="select"
                            name="hallName"
                            className="form-control"
                            value={this.state.hallName}
                            onChange={(event) => {
                              this.setState({ hallName: event.target.value });
                            }}
                          >
                            <option>
                              wählen hall
                            </option>
                            {this.state.hallInfoList.map((item) => {
                              return (
                                <option
                                  key={item.hallName}
                                  value={item.hallName}
                                >
                                  {item.hallName}
                                </option>
                              );
                            })}
                          </select>
                        </FormGroup>
                      </Col>
                    </Row>
                    <br></br>
                    <div className="d-flex flex-row-reverse">
                      <Button
                        className="btn-fill"
                        color="Success"
                        type="submit"
                      >
                        Speichern
                      </Button>
                      <Button
                        style={{ marginRight: 10 }}
                        className="btn-fill"
                        color="warning"
                        onClick={() => this.closeRegForm()}
                      >
                        Abbrechen
                      </Button>
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        ) : null}
      </div>
    );
  }
}

export default Uploadfile;
