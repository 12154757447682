/*!

=========================================================
* Black Dashboard React v1.0.0
=========================================================

* Product Page: https://www.vncdigitalservices.com/product/black-dashboard-react
* Copyright 2019 V-PULSE (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/black-dashboard-react/blob/master/LICENSE.md)

* Coded by V-PULSE

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Router, Route, Switch, Redirect } from "react-router-dom";
import Vendor from "views/Vendor.jsx";
import AdminLayout from "layouts/Admin/Admin.jsx";
import "bootstrap/dist/css/bootstrap.min.css";
import "assets/scss/black-dashboard-react.scss";
import "assets/demo/demo.css";
import "assets/css/nucleo-icons.css";
import "assets/css/custom.css";
import "assets/css/custom-elements.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import ForgotPassword from "./views/ForgotPassword";
import ResetPassword from "./views/ResetPassword";

const hist = createBrowserHistory();

ReactDOM.render(
  <Router history={hist}>
    {(
      localStorage.getItem("token") &&
      localStorage.getItem("token") != null &&
      localStorage.getItem("token") !== ""
        ? true
        : false
    ) ? (
      <Switch>
        <Route path="/admin/vendor" render={(props) => <Vendor {...props} />} />
        <Route path="/admin" render={(props) => <AdminLayout {...props} />} />
        <Route
          path="/forgotpassword"
          render={(props) => <ForgotPassword {...props} />}
        />
        <Route
          path="/resetpassword"
          render={(props) => <ResetPassword {...props} />}
        />
        <Redirect from="/" to="/admin/vendor" />
      </Switch>
    ) : (
      <Switch>
        <Route path="/admin/vendor" render={(props) => <Vendor {...props} />} />
        <Redirect from="/" to="/admin/vendor" />
      </Switch>
    )}
  </Router>,
  document.getElementById("root")
);
