
//import Rtl from "views/Rtl.jsx";
import UserProfile from "views/UserProfile.jsx";
import Uploadfile from "views/Uploadfile.jsx";
import Mapping from "views/Mapping.jsx";
// import Login from "views/Login.jsx";
var routes = [
  {
    path: "/user-profile",
    name: " Benutzerverwaltung",
    //rtlName: "ملف تعريفي للمستخدم",
    icon: "tim-icons icon-single-02",
    component: UserProfile,    
    layout: "/admin"
  },

  {
    path: "/upload-file",
    name: "Daten Management",
    //rtlName: "ملف تعريفي للمستخدم",
    icon: "tim-icons icon-bullet-list-67",
    component: Uploadfile,
    layout: "/admin"
  },
  {
    path: "/mapping",
    name: "Beacon Management",
    //rtlName: "ملف تعريفي للمستخدم",
    icon: "tim-icons icon-bullet-list-67",
    component: Mapping,
    layout: "/admin"
  },
  // {
  //   path: "/vendor",
  //   name: "LOGIN",
  //   //rtlName: "لوحة القيادة",
  //   icon: "tim-icons icon-cart",
  //   component: Vendor,
  //   layout: "/admin"
  //  },
  // {
  //   path: "/icons",
  //   name: "Icons",
  //   //rtlName: "الرموز",
  //   icon: "tim-icons icon-atom",
  //   component: Icons,
  //   layout: "/admin"
  // },
  // {
  //   path: "/map",
  //   name: "Map",
  //   //rtlName: "خرائط",
  //   icon: "tim-icons icon-pin",
  //   component: Map,
  //   layout: "/admin"
  // },
  // {
  //   path: "/notifications",
  //   name: "Notifications",
  //   //rtlName: "إخطارات",
  //   icon: "tim-icons icon-bell-55",
  //   component: Notifications,
  //   layout: "/admin"
  // },
 
  // {
  //   path: "/tables",
  //   name: "Table List",
  //   //rtlName: "قائمة الجدول",
  //   icon: "tim-icons icon-puzzle-10",
  //   component: TableList,
  //   layout: "/admin"
  // },
  // {
  //   path: "/typography",
  //   name: "Typography",
  //  // rtlName: "طباعة",
  //   icon: "tim-icons icon-align-center",
  //   component: Typography,
  //   layout: "/admin"
  // },
  // {
  //   path: "/rtl-support",
  //   name: "RTL Support",
  //   rtlName: "ار تي ال",
  //   icon: "tim-icons icon-world",
  //   component: Rtl,
  //   layout: "/rtl"
  // }
];
export default routes;
