import React, { Component } from 'react';
import axios from 'axios';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { apiUrlEvent, } from '../constants';
import Toast from 'react-bootstrap/Toast';
import SearchField from "react-search-field";
import { Multiselect } from 'multiselect-react-dropdown';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SockJS from 'sockjs-client';
import Stomp from 'stompjs';
import notification from '../assets/alert.mp3';


import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Table,
  FormGroup,
  Form,
  Row,
  Col
} from "reactstrap";

class Mapping extends Component {
  constructor(props) {
    super(props);

    // Don't call this.setState() here!
    this.state = { disabled: false }
    this.state = {
      showValToast: false,
	  showBeaconToast: false,
      beaconId: 0,
      beaconName: '',
      hallId: 0,
      hallName: '',
      machine: '',
      machineId: 0,
      notificationId: 0,
      eventNumber: '',
      hallInfoList: [],
      beaconInfoList: [],
	    beaconInfoEditList: [],
      MachiineInfoList: [],
      // currentDateTime: Date().toLocaleString(),
      // vonDate: "",
      showCurrToast: false,
      searchTerm: "",
      showToast: false,
      showSaveToast: false,
      showErrToast: false,
      showDeleteToast: false,
      rows: [
      ],
      filteredData: [],
      originalData: [],
      searchInput: "",
      selectedFile: null

    };
    
    this.handleShowToast = this.handleShowToast.bind(this);
    this.handleSaveToast = this.handleSaveToast.bind(this);
    this.handleErrToast = this.handleErrToast.bind(this);
    this.componentDidMount = this.componentDidMount.bind(this);
    this.handleSearchChange = this.handleSearchChange.bind(this);
    this.handleCurrDateToast = this.handleCurrDateToast.bind(this);
    this.onSelect = this.onSelect.bind(this);
    this.onRemove = this.onRemove.bind(this);
    this.handleValToast = this.handleValToast.bind(this);
    this.handlDeleteToast = this.handleDeleteToast.bind(this);
	this.handleBeaconExistToast = this.handleBeaconExistToast.bind(this);

    this.saveData = this.saveData.bind(this);
    // this.closeRegForm=this.closeRegForm.bind(this)
  }
  
  handleBeaconExistToast() {
    this.setState({ showBeaconToast: !this.state.showBeaconToast });
  }

  closeRegForm = () => {
    window.location.reload()
  }


  onChange(e) {
    alert(); let files = e.target.files;
    console.warn("data files", files)
  }


  deleteRow(eventNumber, index) {
    const data = localStorage.getItem("token");
    const postData = {
      machineId: this.state.machineId,
      hallId: this.state.hallId,
      beaconId: this.state.beaconId,
      notificationId: eventNumber
  }
  axios.delete(apiUrlEvent + `event/v1/deleteNotificationInfo/${eventNumber}`, { headers: { "Authorization": data } })
      .then(response => {
        let temp = [...this.state.rows];    
        temp.splice(index, 1);
        this.setState({ rows: temp })
      })

    this.handleDeleteToast(eventNumber, index);
  }

  connect() {
    var sock = new SockJS(apiUrlEvent + 'event/ws-message');
    let stompClient = Stomp.over(sock);
    sock.onopen = function () {
      console.log('open');
    }
    let self = this;
    stompClient.connect({}, function (frame) {
      console.log('Connected 2: ' + frame);
      stompClient.subscribe('/topic/messages', function (data) {
        console.log(data.body);
        //you can execute any function here
        self.getdt();
        self.playSound();
        self.showEventToast(JSON.parse(data.body));
      });
    });
  }
  toastClosed(eventId) {
    const data = localStorage.getItem("token");
    axios.get(apiUrlEvent + `event/v1/updateEventSeen/${eventId}`, { headers: { "Authorization": data } })
      .then(response => {
        console.log("seen update response ", response);
      });
  }

  playSound() {
    const audio = new Audio(notification);
    audio.play();
  };

  CustomToastMsg(data) {
    return (
      <div>
        <div><span className="font-weight-bold">Hall :</span> {data.hallName == undefined ? data.hallId : ''}</div>
        <div><span className="font-weight-bold">Machine :</span> {data.machine}</div>
        <div><span className="font-weight-bold">Date :</span> Not found</div>
        <div><span className="font-weight-bold">Time :</span> Not Found</div>
        <div><span className="font-weight-bold">Beacon Number :</span> {data.beaconName}</div>
      </div>);
  }


  showEventToast(msg) {
    toast(this.CustomToastMsg(msg), {
      position: "top-right",
      autoClose: false,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      onClose: () => this.toastClosed(msg.eventId)

    });
  }


  componentWillMount() {

    const data = localStorage.getItem("token");
    axios.get(apiUrlEvent + 'event/v1/getNotifactionInfo', { headers: { "Authorization": data } })
      .then(response => {
        this.setState({ rows: response.data.response })
        this.setState({ originalData: response.data.response })

        let playFlag = false;
        let mappingData = response.data.response;
        for (let i = mappingData.length-1; i >= 0 ; i--) {
          if (mappingData[i].seenFlag == 0) {
            this.showEventToast(mappingData[i]);
            playFlag = true;
          }
        }
        if (playFlag) {
          this.playSound();
        }


      })

    this.setState({
      addDiv: false,
      gridDiv: true,
      savebutton: false,
      editbutton: false
    });
    this.connect();
  }

  states = {
    selectedFile: null

  }
  openAddDiv() {
    this.getHallInfo();
    this.getMachineInfo();
    this.getbeaconList();
    this.setState({
      addDiv: true,
      gridDiv: false,
      savebutton: true,
      editbutton: false
    })

    this.sts = "save";
  }


  editRow(data) {
    this.getbeaconList();
    this.getHallInfo();
    this.getMachineInfo();
    this.onSelect(data.beaconList);

    this.setState({
      addDiv: true,
      gridDiv: false,
      savebutton: false,
      editbutton: true
    })
    this.state.sts = "edit";
    this.state.hallId = data.hallId;
    this.state.beaconId = data.beaconId;
    this.state.machineId = data.machineId;
    this.state.notificationId = data.notificationId;
    this.setState({ disabled: !this.state.disabled })
  }


  async saveData(event) {
  
    if (this.state.beaconInfoList.length > 0) {

  
      event.preventDefault()
      const postData = {
          machineId: this.state.machineId,
          hallId: this.state.hallId,
          beaconId: this.state.beaconId
      }

      await axios.post(apiUrlEvent + 'event/v1/insertNotification', postData, {
        headers: { "Authorization": localStorage.getItem("token") }
      })
        .then(
          res => {
		  console.log(res)
		  if(res.data.response.eventResponseCode === 409){
		  this.handleBeaconExistToast();
		  }else{
            this.handleSaveToast();
            this.componentDidMount();
            window.location.reload(false);
            this.getdt();
            this.setState({
              addDiv: false,
              gridDiv: true,
              savebutton: false,
              editbutton: false
            })
			}
          })
        .catch(exception => {
          this.handleErrToast();
          console.log("Exception", exception)
        })

    } else {
      event.preventDefault();
      this.handleValToast();
    }
  }

  getdt() {
    axios.get(apiUrlEvent + 'event/v1/getNotifactionInfo', { headers: { "Authorization": localStorage.getItem("token") } })
      .then(response => {
        console.log('notificaion data ',response);
        this.setState({ rows: response.data.response.notificationResponseList })
        this.setState({ originalData: response.data.response.notificationResponseList })
      })

  }

  getHallInfo() {
    axios.get(apiUrlEvent + 'event/v1/getHallInfo', { headers: { "Authorization": localStorage.getItem("token") } })
      .then(response => {
        this.setState({ hallInfoList: response.data.response })
      })

  }

  getMachineInfo() {
    axios.get(apiUrlEvent + 'event/v1/getMachineInfo', { headers: { "Authorization": localStorage.getItem("token") } })
      .then(response => {
        this.setState({ MachiineInfoList: response.data.response })
      })

  }

  getbeaconList() {
    axios.get(apiUrlEvent + 'event/v1/getBeaconList', { headers: { "Authorization": localStorage.getItem("token") } })
      .then(response => {
        this.setState({ beaconInfoList: response.data.response })
      })

  }
  
  handleSearchChange(value) {
    this.setState({ searchInput: value }, () => {
      this.globalSearch();
    });
  }


  async updateData(event) {
    if (this.state.beaconInfoList.length > 0) {
      if (!this.state.beaconId) {
        this.setState({ error: "" })
        return;
      }
      if (!this.state.machineId) {
        this.setState({ error: "" })
        return;
      }
      if (!this.state.hallId) {
        this.setState({ error: "" })
        return;
      }
      event.preventDefault()
      const postData = {
        machineId: this.state.machineId,
        hallId: this.state.hallId,
        beaconId: this.state.beaconId,
        notificationId: this.state.notificationId
      }

      await axios.put(apiUrlEvent + `event/v1/updateNotificationInfo`, postData, {
        headers: { "Authorization": localStorage.getItem("token") }
      }).then(
        res => {
		if(res.data.response.eventResponseCode === 409){
		  this.handleBeaconExistToast();
		  }else{
          this.handleSaveToast();
          window.location.reload(false);
          this.readrt();
          this.setState({
            addDiv: false,
            gridDiv: true,
            savebutton: false,
            editbutton: false
          })
		  }
        }
      ).catch(error => {
        this.handleErrToast();
        console.log(error)
      })
    } else {
      event.preventDefault();
      this.handleValToast();
    }
  }

  readrt() {
    axios.get(apiUrlEvent + 'event/v1/getExcelDataFromDb', { headers: { "Authorization": localStorage.getItem("token") } })
      .then(response => {
        this.setState({ rows: response.data.response.notificationResponseList })
        this.setState({ originalData: response.data.response.notificationResponseList })
      })
  }

  handleShowToast() {
    this.setState({ showToast: !this.state.showToast });
  }

  handleCurrDateToast() {
    this.setState({ showCurrToast: !this.state.showCurrToast });
  }

  handleSaveToast() {
    this.setState({ showSaveToast: !this.state.showSaveToast });
  }

  handleValToast() {
    this.setState({ showValToast: !this.state.showValToast });
  }
  
  
   

  onSelect(selectedList) {
    this.state.selectedValue = selectedList;
  }

  onRemove(selectedList, removedItem) {
    this.state.selectedValue = selectedList;
  }

  componentDidMount() {
    setTimeout(function () { //Start the timer
      this.setState({ render: true }) //After 1 second, set render to true
    }.bind(this), 2000)
  }

  handleErrToast() {
    this.setState({ showErrToast: !this.state.showErrToast });
  }


  closeToast() {
    this.setState({ showToast: false });
  }

  handleDeleteToast(eventNumber, index) {

    this.setState({ rowEventNumber: eventNumber, rowIndex: index });
    this.setState({ showDeleteToast: !this.state.showDeleteToast });
  }


  render() {
    return (
      <div className="content">
        <ToastContainer newestOnTop toastStyle={{ backgroundColor: "#1d8cf8", color: 'white' }} />
        <Toast show={this.state.showErrToast} onClose={this.handleErrToast} delay={3000} autohide>
          <Toast.Header>
            <img
              src="holder.js/20x20?text=%20"
              className="rounded mr-2"
              alt=""
            />
            <strong className="mr-auto errClr">Error</strong>
          </Toast.Header>
          <Toast.Body>Exception occured</Toast.Body>
        </Toast>

		
		
		    <Toast show={this.state.showBeaconToast} onClose={this.handleBeaconExistToast} delay={3000} autohide>
          <Toast.Header>
            <img
              src="holder.js/20x20?text=%20"
              className="rounded mr-2"
              alt=""
            />
            <strong className="mr-auto errClr">Error</strong>
          </Toast.Header>
          <Toast.Body>Beacon bereits zugeordnet, löschen Sie bitte die bestehende Zuordnung</Toast.Body>
        </Toast>
		
		

        <Toast show={this.state.showValToast} onClose={this.handleValToast} delay={3000} autohide>
          <Toast.Header>
            <img
              src="holder.js/20x20?text=%20"
              className="rounded mr-2"
              alt=""
            />
            <strong className="mr-auto errClr">Error</strong>
          </Toast.Header>
          <Toast.Body>Bitte wählen Sie das Leuchtfeuer</Toast.Body>
        </Toast>


        <Toast show={this.state.showToast} onClose={this.handleShowToast} delay={3000} autohide>
          <Toast.Header>
            <img
              src="holder.js/20x20?text=%20"
              className="rounded mr-2"
              alt=""
            />
            <strong className="mr-auto errClr">Error</strong>
          </Toast.Header>
          <Toast.Body>Dauervon sollte kleiner als Dauerbis sein</Toast.Body>
        </Toast>

        <Toast show={this.state.showCurrToast} onClose={this.handleCurrDateToast} delay={3000} autohide>
          <Toast.Header>
            <img
              src="holder.js/20x20?text=%20"
              className="rounded mr-2"
              alt=""
            />
            <strong className="mr-auto errClr">Error</strong>
          </Toast.Header>
          <Toast.Body>DateTime sollte größer als currentDatetime sein</Toast.Body>
        </Toast>

        <Toast show={this.state.showSaveToast} onClose={this.handleSaveToast} delay={6000} >
          <Toast.Header>
            <img
              src="holder.js/25x25?text=%25"
              className="rounded mr-2 "
              alt=""
            />
            <strong className="mr-auto updateClr">Message </strong>
          </Toast.Header>
          <Toast.Body>Daten  erfolgreich  gespeichert</Toast.Body>
        </Toast>

        <Toast show={this.state.showDeleteToast} onClose={() => this.handleDeleteToast(this.state.rowEventNumber, this.state.rowIndex)} delay={3000} >
          <Toast.Header>
            <img
              src="holder.js/20x20?text=%20"
              className="rounded mr-2"
              alt="" />

            <strong className="mr-auto errClr">Achtung</strong>
          </Toast.Header>
          <Toast.Body>
            <p>Sind Sie sicher, dass Sie diese Meldung löschen möchten?</p>
            <button className="yes btn btn-primary" onClick={(e) => this.deleteRow(this.state.rowEventNumber, this.state.rowIndex)}>Ja</button>
            <button className="no btn btn-primary" onClick={(e) => this.handleDeleteToast(this.state.rowEventNumber, this.state.rowIndex)}>Nein</button>
          </Toast.Body>
        </Toast>

        {
          this.state.gridDiv ?
            <Row>

              <Col lg="12" >
                <Card className="card-chart">
                  <CardHeader>
                    <h3 className="title m-b-0">Beacon Management</h3>
                  </CardHeader>
                  <CardBody>
                    <Form>

                      <div className="col-lg-12">
                        <input type="button" className="btn btn-success contact_subbtn" value="Hinzufügen" onClick={() => this.openAddDiv()}></input>

                        {/* <input type="file" className="btn btn-primary  contact_subbtn pull-right" style={{ width: "17%", }} name="file" onChange={(e) => this.fileSelectedHandler(e)}></input> */}
                      </div>

                    </Form>
                  </CardBody>
                </Card>
               
                <Card className="card-chart margin-bottom0">
                  <CardBody >
                    <div className="daten-management-box">

                      <Table className="tablesorter upload-daten-management"     >
                        <thead className="text-primary sticky-thead">
                          <tr>
                            {/*<th>Ereignis Nummer</th>*/}
                            <th>Hall Name</th>
                            <th>Machine </th>
                            <th>Beacon Name </th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.rows.map((data, index) => (
                            <tr key={index}>
                              {/* <td>{data.eventNumber}</td> */}
                              <td>{data.hallName}</td>
                              <td>{data.machine}</td>
                              <td>{data.beaconName}</td> 
                              <td className="icon-button">
                                <span className="edit icon-box" onClick={() => this.editRow(data)}>
                                  <img alt="..." src={require("assets/img/edit.png")} style={{ backgroundColor: "white", height: "40px", width: "45px" }} />
                                </span>
                              </td>

                              <td className="icon-button">
                                <span className="delete icon-box" onClick={(e) => this.handleDeleteToast(data.notificationId,index)}>
                                  <img alt="..." src={require("assets/img/delte.png")} style={{ backgroundColor: "white", height: "40px", width: "40px" }} />
                                </span>
                              </td></tr>
                          ))}

                        </tbody>
                      </Table>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            : null
        }

        {
          this.state.addDiv ?
            <Row>
              <Col lg="12">
                <Card>

                  <CardBody>
                    <form onSubmit={event => this.state.sts === "edit" ? this.updateData(event) : this.saveData(event)}>
                      <Row>
                        <Col className="pr-md-1 daten-head-col" md="12">
                          <label>
                            <b>Beacon Management</b>
                          </label>
                        </Col>
                      </Row>
                      <Row>
                        <Col className="pr-md-1 daten-field-col" md="4">
                          <FormGroup>
                            <label>
                              Hall *
                            </label>                          
                            <select type="select" name="hallName" className="form-control" value={this.state.hallId}
                              onChange={event => { this.setState({ hallId: event.target.value }) }} required>
							  <option value="" style={{ color: "black" }}>wählen hall</option>
                                {this.state.hallInfoList.map((item) => {
                                  console.log('state hall id ',this.state.hallId);
                                  return  (<option key={item.hallId} value={item.hallId}>{item.hallName}</option>)
                                })}
                            </select>                        
                          </FormGroup>
                        </Col>

                        <Col className="pr-md-1 daten-field-col" md="4">
                          <FormGroup>
                            <label>
                              Machine *
                            </label>                          
                            <select type="select" name="machine" className="form-control" value={this.state.machineId}
                              onChange={event => { this.setState({ machineId: event.target.value }) }} required>
							  <option value="" style={{ color: "black" }}>wählen machine</option>

                                {this.state.MachiineInfoList.map((item) => {
                                  return  (<option key={item.machineId} value={item.machineId}>{item.machine}</option>)
                                },this)}
                            </select>                        
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                      <Col className="pr-md-1 daten-field-col" md="4">
                          <FormGroup>
                            <label>
                              Beacon *
                            </label>    
							
                            <select type="select" name="beacon" className="form-control" value={this.state.beaconId}
                              onChange={event => { this.setState({ beaconId: event.target.value }) 
							  }} required>
							   <option value="" style={{ color: "black" }}>wählen beacon</option>
                                {this.state.beaconInfoList.map((item) => {
                                  return  (<option key={item.beaconId} value={item.beaconId}>{item.name}</option>)
                                })}
                            
                            </select>                        
                          </FormGroup>
                        </Col>            
                      </Row>                     
                      <Row>
                      </Row>

                      <div style={{ marginTop: 20 }} className="d-flex flex-row-reverse">
                        {
                          this.state.editbutton ?
                            <Button className="btn-fill" color="Success" type="submit" onClick={(event) => this.updateData(event)}>
                              Speichern
                            </Button>
                            : null
                        }
                        {
                          this.state.savebutton ?
                            <Button className="btn-fill" color="Success" type="submit">
                              Speichern
                            </Button>
                            : null
                        }
                        <Button style={{ marginRight: 10 }} className="btn-fill" color="warning" onClick={this.closeRegForm}>
                          Abbrechen
                        </Button>

                      </div>
                    </form>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            : null
        }
      </div>
    );
  }
}


export default Mapping;