import React, { Component } from 'react';
import axios from 'axios';
import { apiUrlUser } from '../constants'

class vendor extends Component {
  constructor(props) {
    super(props)

    this.state = {
      username: '',
      password: '',
      invalidLogin: null
    }
  }


  keyPress = (e) => {
    if (e.keyCode === 13) {
      this.handleSubmit(e);
    }
  }

  passwordHandle = (e) => {
    this.setState({ password: e.target.value })

  }

  isLoggedIn = true;
  handleSubmit = (event) => {

    const user = {
      username: this.state.username,
      password: this.state.password,
      userLoginType: 2
    };
    event.preventDefault();
    axios.post(apiUrlUser + `user/v1/login`, user).then(
      res => {
        if ((res.data.response.loginResponseCode === 20001) || (res.data.response.loginResponseCode === 20007)) {
          const error = <p style={{ color: 'red' }}>
            Ungültige Anmeldeinformationen</p>
          this.setState({ errorMessage: error })
        } else if (res.data.response.userManagementResponse.userRole === "ADMIN") {
          localStorage.setItem("token", "Bearer" + res.data.response.authResponse.oAuth2AccessToken.access_token);
          // sessionStorage.setItem("isLoggedIn", true);
          setTimeout(function () {
          }, 2000);
          // this.props.history.push("/admin/upload-file");
          window.location.href = "/admin/upload-file";
        }
        else {
          const error = <p style={{ color: 'red' }}>User Access Denied</p>
          this.setState({ errorMessage: error })
        }
      }
    ).catch(error => {
      console.log(error)
    })



  }
  componentDidMount() {
    // localStorage.clear();

  }
  // {    document.body.style.backgroundColor = "#00ffdc99"}

  render() {
    if (this.props.match.params.required === 'false') {
      this.isLoggedIn = false;
      // localStorage.clear();

    }
    else {
      this.isLoggedIn = true

    }
    return (
      // <Card style={{paddingLeft:"600px",height:"300px"}} >
      <div style={{
        backgroundColor: "lite-green",
        display: "flex",
        borderradius: "4px",
      }} className="Login-box">


        <div className="base-container" ref={this.props.containerRef}>
          <div style={{
            display: "flex",
            borderradius: "10px",
            //  boxshadow: "0px 0px 55px 2px rgba(15, 15, 15, 0.2)",
          }} ><h1>Einloggen</h1></div>
          <br></br>
          <div className="">
            {this.state.errorMessage ? this.state.errorMessage : null}
            <div className="">
              <img alt="..." src={require("assets/img/anime3.png")} style={{ backgroundColor: "white", height: "3cm" }} />
            </div>
            <div className="form" style={{ paddingTop: 5 }}>
              <form onSubmit={(event) => this.handleSubmit(event)}>
                <div className="form-group">
                  <label htmlFor="username">Benutzername</label>
                  <input type="text" name="username" placeholder="Benutzername" className="form-control" required onChange={event => { this.setState({ username: event.target.value }) }} noValidate />
                </div>
                <div className="form-group">
                  <label htmlFor="password">Passwort</label>
                  <input type="password" name="password" placeholder="Passwort" className="form-control" required onChange={this.passwordHandle} onKeyDown={(e) => this.keyPress(e)} noValidate />
                </div>
                <div>
                  <p><a href="/forgotpassword" >Passwort vergessen?</a></p>
                </div>
              </form>
            </div>

          </div>
          <div className="footer">

            <button type="submit" onClick={this.handleSubmit} className="btn btn-warning contact_subbtn">
              Einloggen
            </button>
          </div>
          {/* <div className="form-group">
        <Link to="/admin">Forgot Password?</Link>
        </div> */}
        </div>

      </div>
      //  </Card>
    );
  }
}


export default vendor;