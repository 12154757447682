import React from "react";
import $ from "jquery";
import "assets/css/react-js.css";
import { Table } from "reactstrap";
import Toast from 'react-bootstrap/Toast';
import { apiUrlUser } from '../constants'
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Row,
  Col
} from "reactstrap";
import axios from 'axios';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";
const eye = <FontAwesomeIcon icon={faEye} />;

class UserProfile extends React.Component {
  constructor(props) {
    super(props);
    this.state = { disabled: false }
    this.state = {
      username: '',
      fullName: '',
      email: '',
      password: '',
      role: '',
      userStatus: '',
      passwordShown: false,
      showSaveToast: false,
      showErrorToast: false,
      showDeleteToast: false,
      showEmailAlreadyExistToast: false,
      passwordRequired: false,
      responses: 0

    };

    this.handleSaveToast = this.handleSaveToast.bind(this);
    this.handleErrorToast = this.handleErrorToast.bind(this);
    this.handlDeleteToast = this.handleDeleteToast.bind(this);
    this.handleEmailToast = this.handleEmailToast.bind(this);

  }

  componentWillMount() {

    this.setState({
      showdatagrid: true,


    })
    this.getUserDataInfo();
  }


  getUserDataInfo() {
    const data = localStorage.getItem("token");
    axios.get(apiUrlUser + 'user/v1/findAllUsers', { headers: { "Authorization": data } })
      .then(response => {
        this.setState({ rows: response.data.response.userDetailsDtoList })

      })
    //   } else {
    //     this.props.history.push("/admin/vendor");
    //   }
    // }
  }

  async handleSubmit(event) {

    event.preventDefault()

    if (this.sts === "save") {

      if (this.state.role === "Select Role") {
        alert("Please Select a Role!");
      }
      else {
        const register = {
          username: this.state.username,
          fullName: this.state.fullName,
          email: this.state.email,
          password: this.state.password,
          userRole: {
            role: this.state.role
          },
          isUserActive: this.state.userStatus,
        };

        await axios.post(apiUrlUser + "user/v1/register", register).then(
          res => {
            this.state.responses = res.data.response.userManagementResponseCode;
          }


        ).catch(error => {
          console.log(error)
        })
      }

      if (this.state.responses === 20015) {
        this.handleEmailToast();
      }
      else if (this.state.responses !== 20002) {
        this.handleSaveToast();
        this.getUserDataInfo();
        window.location.reload(false);

      }
      else {
        this.handleErrorToast();

      }
    }

    else {

      const registers = {
        username: this.state.username,
        fullName: this.state.fullName,
        userRole: {
          role: this.state.role
        },
        password: this.state.password,
        email: this.state.email,
        isUserActive: this.state.userStatus
      }

      const data = localStorage.getItem("token");
      await axios.put(apiUrlUser + 'user/v1/updateUser', registers, { headers: { "Authorization": data } })
        .then(response => {
          this.state.responses = response.data.response.userManagementResponseCode;
        })

      if (this.state.responses === 20015) {
        this.handleEmailToast();
      }
      else {
        this.handleSaveToast();
        window.location.reload(false);
      }
    }
  }

  openRegistration(status) {
    this.setState({ disabled: false })
    this.sts = status;
    this.setState({
      showRegdiv: true,
      showdatagrid: false,
      save: true,
      passwordRequired: true,
    });

  }

  editSubmit(data, status) {
    this.state.username = data.username;
    this.state.fullName = data.fullName;
    this.state.password = data.password;
    this.state.email = data.email;
    this.state.role = data.roleName;
    this.state.userStatus = data.userStatus === 'Active' ? true : false;
    this.sts = status;
    this.setState({
      showRegdiv: true,
      showdatagrid: false,
      passwordRequired: false,

    })

    this.setState({ disabled: !this.state.disabled })
  }

  deleteSubmit(username, index) {

    const data = localStorage.getItem("token");

    axios.delete(apiUrlUser + `user/v1/deleteData/${username}`, { headers: { "Authorization": data } })
      .then(response => {
        let temp = [...this.state.rows];
        temp.splice(index, 1);
        this.setState({ rows: temp })
      })
    this.handleDeleteToast(username, index);
  }

  handleSaveToast() {
    this.setState({ showSaveToast: !this.state.showSaveToast });
  }

  closeRegForm() {
    window.location.reload(false);
    this.getUserDataInfo();
  }

  handleErrorToast() {
    this.setState({ showErrorToast: !this.state.showErrorToast });
  }

  handleDeleteToast(username, index) {

    this.setState({ rowuserName: username, rowIndex: index });
    this.setState({ showDeleteToast: !this.state.showDeleteToast });
  }

  handleEmailToast() {
    this.setState({ showEmailAlreadyExistToast: !this.state.showEmailAlreadyExistToast });
  }

  render() {
    const passwordRequired = this.state.passwordRequired;

    if (this.props.match.params.required === 'false') {
      this.isLoggedIn = false;

    }
    else {
      this.isLoggedIn = true
    }
    return (
      <div className="content">
        <Toast show={this.state.showSaveToast} onClose={this.handleSaveToast} delay={5000} autohide>
          <Toast.Header>
            <img
              src="holder.js/20x20?text=%20"
              className="rounded mr-2"
              alt=""
            />
            <strong className="mr-auto">Message</strong>
          </Toast.Header>
          <Toast.Body>Daten erfolgreich gespeichert</Toast.Body>
        </Toast>

        <Toast show={this.state.showErrorToast} onClose={this.handleErrorToast} delay={5000} autohide>
          <Toast.Header>
            <img
              src="holder.js/20x20?text=%20"
              className="rounded mr-2"
              alt=""
            />
            <strong className="mr-auto">Error</strong>
          </Toast.Header>
          <Toast.Body>Benutzerdetails sollten eindeutig sein</Toast.Body>
        </Toast>

        <Toast show={this.state.showDeleteToast} onClose={() => this.handleDeleteToast(this.state.rowuserName, this.state.rowIndex)} delay={3000} >
          <Toast.Header>
            <img
              src="holder.js/20x20?text=%20"
              className="rounded mr-2"
              alt="" />

            <strong className="mr-auto errClr">Achtung</strong>
          </Toast.Header>
          <Toast.Body>
            <p>Sind Sie sicher, dass Sie diesen User löschen möchten?</p>
            <button className="yes btn btn-primary" onClick={(e) => this.deleteSubmit(this.state.rowuserName, this.state.rowIndex)}>Ja</button>
            <button className="no btn btn-primary" onClick={(e) => this.handleDeleteToast(this.state.rowuserName, this.state.rowIndex)}>Nein</button>
          </Toast.Body>
        </Toast>

        <Toast show={this.state.showEmailAlreadyExistToast} onClose={this.handleEmailToast} delay={5000} autohide>
          <Toast.Header>
            <img
              src="holder.js/20x20?text=%20"
              className="rounded mr-2"
              alt=""
            />
            <strong className="mr-auto">Warnung</strong>
          </Toast.Header>
          <Toast.Body>E-Mail ist bereits vorhanden !!</Toast.Body>
        </Toast>

        {
          this.state.showdatagrid ?
            <Row>
              <Col xs="12">
                <button type="button" className="btn btn-success contact_subbtn new-user-btn" onClick={() => this.openRegistration('save')} >
                  Neuen User anlegen
                </button>
              </Col>
            </Row>
            : null
        }

        {
          this.state.showdatagrid ?
            <Row style={{ paddingTop: "10px" }}>

              <Col xs="12">
                <Card className="card-chart">
                  <CardBody style={{ marginBottom: "-5px" }}>
                    <Table className="tablesorter" responsive>
                      <thead className="text-primary">
                        <tr>
                          <th>Benutzername </th>
                          <th>Vor- &amp; Nachname</th>
                          <th>Email</th>
                          <th>Status</th>
                          <th>Bearbeiten</th>
                          <th>Löschen</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.rows && this.state.rows.map((data, index) => (

                          <tr key={index}>
                            <td>{data.username}</td>
                            <td>{data.fullName}</td>
                            <td>{data.email}</td>
                            <td>{data.userStatus === 'Active' ? 'Aktiv' : 'Inaktiv'}</td>
                            <td className="icon-button">
                              <span className="edit icon-box new-user-btn" onClick={(e) => this.editSubmit(data, 'edit')}>
                                <img alt=" " src={require("assets/img/edit.png")} style={{ backgroundColor: "white", height: "40px", width: "45px" }} />
                              </span>
                            </td>

                            <td className="icon-button">
                              <span className="delete icon-box new-user-btn" onClick={(e) => this.handleDeleteToast(data.username, index)}>
                                <img alt=" " src={require("assets/img/delte.png")} style={{ backgroundColor: "white", height: "40px", width: "40px" }} />
                              </span>
                            </td>

                          </tr>
                        ))}

                      </tbody>

                    </Table>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            : null
        }
        {
          this.state.showRegdiv ?
            <Row>
              <Col xs="12">
                <Card className="card-chart">
                  <CardHeader style={{ marginBottom: "10px" }}>
                    <h3 className="title"> NEUEN USER ANLEGEN </h3>
                  </CardHeader>
                  <CardBody style={{ marginBottom: "-5px", padding: 20 }}>
                    <Form onSubmit={(event) => this.handleSubmit(event)}>
                      <Row>
                        <br></br>
                        <Col className="pr-md-1" md="6">
                          <FormGroup>
                            <label >Vor- &amp; Nachname *</label>
                            <input type="text" name="fullName" placeholder="Vor- &amp; Nachname" className="form-control" required value={this.state.fullName} onChange={event => { this.setState({ fullName: event.target.value }) }} noValidate />
                          </FormGroup>
                        </Col>
                        <Col className="pr-md-1" md="6">
                          <FormGroup>
                            <label  >Email *</label>
                            <input type="text" pattern="[a-zA-Z0-9.-_]{1,}@[a-zA-Z.-]{2,}[.]{1}[a-zA-Z]{2,}" name="email" placeholder="Email Id" className="form-control" required value={this.state.email} onChange={event => { this.setState({ email: event.target.value }) }} />
                          </FormGroup>
                        </Col>
                        <Col className="pr-md-1" md="6">
                          <FormGroup>
                            <label  >Benutzername *</label>
                            <input type="text" name="username" placeholder="Benutzername" disabled={(this.state.disabled) ? "disabled" : ""} className="form-control" required value={this.state.username} onChange={event => { this.setState({ username: event.target.value }) }} noValidate />
                          </FormGroup>
                        </Col>
                        <Col className="pr-md-1" md="6">
                          <div>
                            {passwordRequired ?
                              (<FormGroup>
                                <label>Passwort *</label>
                                <input type={this.state.passwordShown ? "text" : "password"} name="Password" placeholder="Passwort" className="form-control" required value={this.state.password} onChange={event => { this.setState({ password: event.target.value }) }} noValidate />
                                <i className="show-pass-icon" onClick={(event) => this.setState({ passwordShown: !this.state.passwordShown })} >{eye}</i>
                              </FormGroup>) :

                              (<FormGroup>
                                <label>Neues Passwort</label>
                                <input type={this.state.passwordShown ? "text" : "password"} name="Password" placeholder="Passwort" className="form-control" value={this.state.password} onChange={event => { this.setState({ password: event.target.value }) }} noValidate />
                                <i className="show-pass-icon" onClick={(event) => this.setState({ passwordShown: !this.state.passwordShown })} >{eye}</i>
                              </FormGroup>)
                            }
                          </div>
                        </Col>
                        <Col className="pr-md-1" md="6">
                          <FormGroup>
                            <label >Rolle *</label>
                            <select type="select" name="role" className="form-control" required value={this.state.role} onChange={event => { this.setState({ role: event.target.value }) }} noValidate>
                              <option value="" style={{ color: "black" }}>Rolle wählen</option>
                              <option value="USER" style={{ color: "black" }}>USER</option>
                              <option value="ADMIN" style={{ color: "black" }}>ADMIN</option>
                            </select>
                          </FormGroup>
                        </Col>
                        <Col className="pr-md-1" md="6">
                          <FormGroup>
                            <label >Status ändern *</label>
                            <select type="select" name="role" className="form-control" required value={this.state.userStatus} onChange={event => { this.setState({ userStatus: event.target.value }) }} noValidate>
                              <option value="" style={{ color: "black" }}>Status wählen</option>
                              <option value="true" style={{ color: "black" }}>Aktiv</option>
                              <option value="false" style={{ color: "black" }}>Inaktiv</option>
                            </select>
                          </FormGroup>
                        </Col>
                      </Row>
                      <div className="d-flex flex-row-reverse">
                        <Button className="btn-fill" color="Success" type="submit">
                          Speichern
                        </Button>
                        <Button style={{ marginRight: 10 }} className="btn-fill" color="warning" onClick={() => this.closeRegForm()}>
                          Abbrechen
                        </Button>
                      </div>
                    </Form>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            : null

        }
      </div>
    );

  };
}
// jQuery for scroll top for Reg div
$(document).on('click', '.new-user-btn', function () {
  $('.main-panel').scrollTop(0);
})

export default UserProfile;