import axios from 'axios';
import React, { Component } from 'react';
import { FormGroup } from 'reactstrap';
import Toast from 'react-bootstrap/Toast';

import { apiUrlUser } from '../constants';

class ForgotPassword extends Component {
  constructor(props) {
    super(props);

    this.state = {
      usernameOrEmail: '',
      showUserNotExistToast: false,
      showLinkSuccesfullToast: false,
      baseURL:window.location.origin.toString(),
      
    }
    this.handleUserNotExistToast = this.handleUserNotExistToast.bind(this);
    this.handleLinkSuccesfullToast = this.handleLinkSuccesfullToast.bind(this);
  }

  setErrors = error =>
  this.setState({
    errors: { ...this.state.errors, ...error }
  });

  handleSubmit(event){
    event.preventDefault()

    axios.post(apiUrlUser + "user/v1/forgotPassword",{emailorusername:this.state.usernameOrEmail,
    baseURL:this.state.baseURL})
    .then(response => {
      if(response.data.response.userManagementResponseCode === 20014){
        this.handleUserNotExistToast();
        
      }
      else{
        this.handleLinkSuccesfullToast();
        setTimeout(() => this.setErrors(this.props.history.push("/admin/vendor")), 4000);
        
      }
    })
    .catch(error => {
      console.log(error)})
  }

  handleUserNotExistToast() {
    this.setState({ showUserNotExistToast: !this.state.showUserNotExistToast });
  }
  handleLinkSuccesfullToast() {
    this.setState({ showLinkSuccesfullToast: !this.state.showLinkSuccesfullToast });
  }


  render() {
    return (
      <div style={{
        display: "flex"
      }} className="Login-box">
        <div className="base-container" ref={this.props.containerRef}>
          <div style={{
            display: "flex",
            borderradius: "10px",
            //  boxshadow: "0px 0px 55px 2px rgba(15, 15, 15, 0.2)",
          }} ><h1>Einloggen</h1></div>
          <br></br>
          <div className="">
            {this.state.errorMessage ? this.state.errorMessage : null}
            <div className="">
              <img alt="..." src={require("assets/img/anime3.png")} style={{ backgroundColor: "white", height: "3cm" }} />
            </div>
            <div className="form" style={{ paddingTop: 5 }}>
              <form onSubmit={(event) => this.handleSubmit(event)}>
                <FormGroup>
                  <div className="form-group">
                    <label htmlFor="username">Benutzername oder E-Mail</label>
                    <input className="form-control" type="text" name="usernameOrEmail" required value={this.state.usernameOrEmail} onChange={event => { this.setState({ usernameOrEmail: event.target.value }) }} noValidate />
                  </div>
                </FormGroup>
                <div>
                  <p><a href="/admin/vendor" >Zurück zur Anmeldeseite</a></p>
                </div>
                <div className="footer form-group">
                  <button type="submit" className="btn btn-warning contact_subbtn" >
                    Einreichen
                  </button>
                </div>
              </form>
            </div>

            { /* Toast:- User Does Not exist by entered details*/ }
            <Toast show={this.state.showUserNotExistToast} onClose={this.handleUserNotExistToast} delay={3000} autohide >
              <Toast.Header>
                <img
                  src="holder.js/20x20?text=%20"
                  className="rounded mr-2"
                  alt=""
                />
                <strong className="mr-auto errClr">Error</strong>
              </Toast.Header>
              <Toast.Body>Benutzer existiert nicht</Toast.Body>
            </Toast>


            { /* Toast:- Password reset link has been send succesfully*/ }
            <Toast show={this.state.showLinkSuccesfullToast} onClose={this.handleLinkSuccesfullToast} delay={3000} autohide>
              <Toast.Header>
                <img
                  src="holder.js/20x20?text=%20"
                  className="rounded mr-2"
                  alt=""
                />
                <strong className="mr-auto errClr">Success</strong>
              </Toast.Header>
              <Toast.Body>Der Link zum Zurücksetzen des Passworts wurde erfolgreich gesendet</Toast.Body>
            </Toast>




          </div>
          {/* <div className="form-group">
          <Link to="/admin">Forgot Password?</Link>
          </div> */}
        </div>
      </div>
    );
  }

}

export default ForgotPassword;