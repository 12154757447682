import React, { Component } from 'react';
import { FormGroup } from 'reactstrap';
import Toast from 'react-bootstrap/Toast';
import Axios from 'axios';
import { apiUrlUser } from '../constants';

class ResetPassword extends Component {
  constructor(props) {
    super(props);

    const query = new URLSearchParams(this.props.location.search);

    this.state = {
      newPassword: '',
      confirmNewPassword: '',
      showErrToast: false,
      showPassChangedToast: false,
      showLinkExpiredToast: false,
      token: query.get('token'),
    }


    this.handleErrToast = this.handleErrToast.bind(this);
    this.handlePassChangedToast = this.handlePassChangedToast.bind(this);
    this.handleLinkExpiredToast = this.handleLinkExpiredToast.bind(this);

  }

  setErrors = error =>
    this.setState({
      errors: { ...this.state.errors, ...error }
    });

  handleSubmit(event) {
    event.preventDefault()
    if (this.state.confirmNewPassword === this.state.newPassword) {

      const updatePasswordParams = {
        token: this.state.token,
        password: this.state.newPassword,
      };

      Axios.post(apiUrlUser + "user/v1/resetPassword", updatePasswordParams)
        .then(response => {
          if (response.data.response.authResponse.authResponseCode === 20000) {
            this.handlePassChangedToast();
            setTimeout(() => this.setErrors(this.props.history.push("/admin/vendor")), 5000);

          }
          else {
            this.handleLinkExpiredToast();

          }
        })
        .catch(error => {
          console.log(error)
        })

    }
    else {
      this.handleErrToast();
    }
    // await window.location.reload(false);
  }

  handleErrToast() {
    this.setState({ showErrToast: !this.state.showErrToast });
  }
  handlePassChangedToast() {
    this.setState({ showPassChangedToast: !this.state.showPassChangedToast });
  }
  handleLinkExpiredToast() {
    this.setState({ showLinkExpiredToast: !this.state.showLinkExpiredToast });
  }

  render() {
    return (
      <div style={{
        display: "flex"
      }} className="Login-box">
        <div className="base-container" ref={this.props.containerRef}>
          <div style={{
            display: "flex",
            borderradius: "10px",
            //  boxshadow: "0px 0px 55px 2px rgba(15, 15, 15, 0.2)",
          }} ><h1>Einloggen</h1></div>
          <br></br>
          <div className="">
            {this.state.errorMessage ? this.state.errorMessage : null}
            <div className="">
              <img alt="..." src={require("assets/img/anime3.png")} style={{ backgroundColor: "white", height: "3cm" }} />
            </div>
            <div className="form" style={{ paddingTop: 5 }}>
              <form onSubmit={(event) => this.handleSubmit(event)}>
                <FormGroup>
                  <div className="form-group">
                    <label htmlFor="username">New Passwort</label>
                    <input className="form-control" type="password" name="newPassword" placeholder="Neues Passwort" required value={this.state.newPassword} onChange={event => { this.setState({ newPassword: event.target.value }) }} noValidate />
                  </div>
                </FormGroup>
                <FormGroup>
                  <div className="form-group">
                    <label htmlFor="password">Confirm Passwort</label>
                    <input className="form-control" type="password" name="reEnterNewPassword" placeholder="Bestätige Neues Passwort" required value={this.state.confirmNewPassword} onChange={event => { this.setState({ confirmNewPassword: event.target.value }) }} noValidate />
                  </div>
                </FormGroup>
                <div>
                  <p><a href="/admin/vendor" >Zurück zur Anmeldeseite</a></p>
                </div>
                <div className="footer">

                  <button type="submit" className="btn btn-warning contact_subbtn">
                    einreichen
                  </button>
                </div>
              </form>
            </div>
            { /* Toast:- Confirm Password Should be equal to new password*/}
            <Toast show={this.state.showErrToast} onClose={this.handleErrToast} delay={3000} autohide>
              <Toast.Header>
                <img
                  src="holder.js/20x20?text=%20"
                  className="rounded mr-2"
                  alt=""
                />
                <strong className="mr-auto errClr">Error</strong>
              </Toast.Header>
              <Toast.Body>Passwort bestätigen Sollte dem neuen Passwort entsprechen</Toast.Body>
            </Toast>

            { /* Toast:- Password has been changed*/}
            <Toast show={this.state.showPassChangedToast} onClose={this.handlePassChangedToast} delay={5000} autohide>
              <Toast.Header>
                <img
                  src="holder.js/20x20?text=%20"
                  className="rounded mr-2"
                  alt=""
                />
                <strong className="mr-auto errClr" style={{ color: "#4DD637" }}>Success</strong>
              </Toast.Header>
              <Toast.Body>Das Passwort wurde geändert</Toast.Body>
            </Toast>

            { /* Toast:- Password reset link had expired*/}
            <Toast show={this.state.showLinkExpiredToast} onClose={this.handleLinkExpiredToast} delay={5000} autohide>
              <Toast.Header>
                <img
                  src="holder.js/20x20?text=%20"
                  className="rounded mr-2"
                  alt=""
                />
                <strong className="mr-auto errClr">Error</strong>
              </Toast.Header>
              <Toast.Body>Der Link zum Zurücksetzen des Passworts war abgelaufen</Toast.Body>
            </Toast>

          </div>
          {/* <div className="form-group">
          <Link to="/admin">Forgot Password?</Link>
          </div> */}
        </div>
      </div>
    );
  }

}

export default ResetPassword;